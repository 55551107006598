.product_cardDiv {
  /* background: #FFFFFF; */
  
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  /* height: 304px; */
  /* width: 240px; */
  /* left: 85px;
  top: 1452px; */
  border-radius: 12px;
  /* padding: 12px 0px 12px 0px; */
  padding: 12px 0px 16px;
  /* offset: 0px, 1px rgba(0, 0, 0, 0.15); */
  width: 256px;
}

.product_cardSubTitle {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #5B5B5B;
  border-radius: 8px;
  height: 27px;
  width: fit-content;
  padding: 4px 16px;
  background: #F5F5F5;
}

.product_cardTitle {
  /* height: 24px;
  width: 104px; */
    font-family: 'Source Sans Pro';

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #2c2c2c;
}

.cardDotIcon {
  height: 8px;
  width: 8px;

  border-radius: 5px;
}

.cardDownloadButtonDiv {
  height: 45px;
  width: 191px;
  left: 24.5px;
  top: 243px;
  border-radius: 8px;
  border: 1px solid #72c06e;
  background: #e3f4e3;
}

.cardDownloadButton {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #31a246;
  width: 100%;
  height: 100%;
}

.cardErrorButtonDiv {
  height: 45px;
  width: 191px;
  left: 24.5px;
  top: 243px;
  border-radius: 8px;
  background: #fff8f7;
  border: 1px solid #de3730;
}

.cardErrorButton {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #de3730;
  width: 100%;
  height: 100%;
}

.cardProcessButtonDiv {
  height: 45px;
  width: 191px;
  left: 24.5px;
  /* top: 243px; */
  border-radius: 8px;
  background: #F5F5F5;
  /* border: 1px solid #f5f5f5; */
}
.cardProcessButton {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5B5B5B;
  width: 100%;
  height: 100%;
}
.emptyProduct{
  background: transparent;
    border: none;
    box-shadow: none;
    cursor: default;
}
.emptyProSub{
  background: transparent;
}
.cardUnderReviewButtonDiv{
  height: 45px;
width: 191px;
left: 32.5px;
top: 252px;
border-radius: 8px;
background: #F5F5F5;
}
.cardUnderReviewButton{
    font-family: 'Source Sans Pro';

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #5B5B5B;
  width: 100%;
  height: 100%;
  
}

.cardsImage{
  width: 180px;
height: 140px;
}
.product_cardDiv:hover .dotDiv {
  /* position: absolute; */
  /* right: 25px; */
  /* right: calc(15px + 10px); */
  right: 19px;
}

@media screen and (min-width: 1110px) {
  .product_cardDiv {
    width: 256px;
    /* height: 288px; */
  }
}

@media screen and (max-width: 570px) {
  .product_cardDiv {
    /* width: 212px; */
    width: 256px;

    /* height: 288px; */
  }
}
