.table-head {
  font-size: 14px;
  color: #3f3f3f;
  line-height: 20px;
}
.css-1ygcj2i-MuiTableCell-root {
  font-weight: 700 !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  position: relative !important;
}
.top-row {
  height: 48px;
  background: #fbfbfb;
}
.table-rows {
  height: 96px;
  background: #ffffff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.button-table {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 12px;
  background: #f5f9ff;
  border-radius: 50px;
}
.btn-confirm {
  color: #1f65d1;
  border: 1px solid #1f65d1;
  width: 122px;
  height: 24px;
}
.btn-status {
  color: #707070;
  border: 1px solid #707070;
  width: 108px;
  height: 24px;
}

.p-image {
  /* width: 102.4px; */
  /* height: 80px; */
  width: 86px;
  height: 64px;
  object-fit: cover;
}
.review-text {
  font-size: 12px;
  color: #707070;
  font-weight: 500;
}
.arrow-icon {
  color: #707070;
  height: 15px;
  margin-left: 16px;
  cursor: pointer;
}
.css-1ex1afd-MuiTableCell-root {
  border-bottom: 0px !important;
}
