.placeholder {
  position: relative;
  font-size: 12px;
}

.ptag {
  position: absolute;
  margin: 7px 0;
  font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #bebebe;
  margin-top: 19px;
  padding-left: 37px;
}

.inputstyle {
  position: absolute;
  left: 0;
  background-color: transparent;
  padding-left: 5px;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #9d9d9d;
  border-radius: 8px;
  background-color: #ffffff;
}

/* .PhoneInput{
    
   
} */

/* .placeholder::after
{
    position: absolute;
    left: 5px;
    top: 3px;
    content: attr(placeholder);
    pointer-events: none;
    opacity: 0.6;
} */
