.dropbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: 260px;
  padding: 40px 0px;
  background: #fbfbfb;
  border: dashed 1px #9d9d9d;
  border-radius: 5px;
}
#btn1 {
  padding: 10px 20px;
  width: 188px;
  height: 44px;
  background: #40a63a;
  border-radius: 5px;
}
#btn2 {
  padding: 10px 20px;
  width: 140px;
  height: 44px;
  color: #3d3d3d;
  background: #ffffff;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.word-line::before,
.word-line::after {
  content: "";
  display: inline-block;
  width: 48px;
  height: 1px;
  background-color: #bebebe;
  vertical-align: middle;
  margin: 0 10px;
}

.word-line {
  display: inline-block;
  font-size: 24px;
  color: #2c2c2c;
  font-weight: bold;
}

.sendphotomodal {
  height: 168px;
  background-color:#ffffff ;
  width: 352px;
  border-radius: 5px;
}
.sendphotomodal p {
  font-size: 16px;
}
.error_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 8px;
  width: 360px;
  height: 36px;
  background: #fff8f7;
  color: #de3730;
  border: 1px solid #de3730;
  border-radius: 5px;
}
