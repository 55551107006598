.smallpopupmodal {
  /* width: 338px; */
  height: 60px;
  padding: 16px 20px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 22px;
  background: #e3f4e3;
  color: #174f13;
  border: 2px solid #31a246;
  border-radius: 4px;
}
