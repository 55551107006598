.certificateMaiDiv {
  height: auto;
  /* width: 360px; */
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
}

.certifiacteDiv {
  height: 364px;
  width: 256px;
  border-radius: 5px;
}
/* .blurEffect{
  filter: blur(3px);
} */

.certifiactePictureCards {
  height: 120px;
  width: 120px;

  border-radius: 5px;
  border: 1px solid #e7e7e7;
}

.productDetailViewDiv {
  /* height: 280px; */
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.productDetailRow {
  height: 40px;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

.productDetailRow1stPart {
  height: 40px;
  width: 180px;

    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

  padding: 10px 8px 10px 8px;
  /* border: 1px solid #e7e7e7; */
  /* border-top:1px solid #e7e7e7; */
  /* border-left:1px solid #e7e7e7; */
  border-right: 1px solid #e7e7e7;
}

.productDetailRow2ndPart {
  height: 40px;
  width: 140px;
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;

  padding: 10px 8px 10px 8px;
  /* border-top:1px solid #e7e7e7;
  border-right:1px solid #e7e7e7; */
}
.productDetailRowBorder {
  border-bottom: 1px solid #e7e7e7;
}

.qrMainDiv {
  height: 100%;
  /* width: 468px; */
  border-radius: 0px;
}

.qrTitle {
    font-family: 'Source Sans Pro';

  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.qrSubTitle {
    font-family: 'Source Sans Pro';

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #707070;
}

.productTransferFormDiv {
  position: absolute;
  width: inherit;
  height: 100%;
  /* width: 508px; */
  margin-bottom: 45px;
  border-radius: 5px;
  padding: 54px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 54px;
  gap: 20px;
  margin-top: 74px;
}

.tranferFormTopbar {
  height: 48px;
  background: #fbfbfb;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 20px;

    font-family: 'Source Sans Pro';

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
}

.transferDialogMianDiv {
  height: 328px;
  width: 400px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 88px;
  gap: 34px;
}

/* Media query for mobile phones */
@media only screen and (max-width: 600px) {
  .transferDialogMianDiv {
    height: auto;
    width: 100%;
    padding: 0px 16px 88px;
    gap: 20px;
  }
}

/* Media query for larger screens */
@media only screen and (min-width: 601px) {
  .transferDialogMianDiv {
    height: 328px;
    width: 400px;
    padding: 0px 0px 88px;
    gap: 34px;
  }
}

.transferDialogTitle {
    font-family: 'Source Sans Pro';

  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #2c2c2c;
}

.transferDialogSubTitle {
    font-family: 'Source Sans Pro';
  width: 270px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #707070;
}

.viewAllTransferText {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #707070;
}

.tranferListHeadTitle {
    font-family: 'Source Sans Pro';

  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #2c2c2c;
}

.transferListCardMainDiv {
  height: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background: #ffffff;
}

.transferCardDiv {
  /* height: 96px; */
  border-radius: 10px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 10px;
  background: #ffffff;
}

.transferListDate {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #9d9d9d;
}

.transferListTitle {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #2c2c2c;
}
.tranferListEmail {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #707070;
}

.productDetailViewDivMobi {
  border-radius: 4px;
  border: 1px solid #e7e7e7;
}
.productDetailViewChildDivMobi {
  border-bottom: 1px solid #e7e7e7;

  padding: 8px;
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.productDetailLabelMobi {
    font-family: 'Source Sans Pro';

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #2c2c2c;
}

.productDetailText {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #2c2c2c;
}

.qrSubTitleMobi {
    font-family: 'Source Sans Pro';

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #707070;
  float: left;
}

.qrTitleMobi {
    font-family: 'Source Sans Pro';

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #2C2C2C;
}
.productQr{
  padding: 22px;
    margin: 0px auto;
}
