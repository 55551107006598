.title {
  border-radius: 0px;
  color: #707070;
  font-family: "Source Sans Pro";

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputErrorShown{
  border: 1px solid #DE3730 !important;
}

.navbrtext {
  font-family: "Source Sans Pro";

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.textButton {
  font-family: "Source Sans Pro";

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 575px) {
  .headline {
    font-family: "Source Sans Pro";

    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    line-height: 98%;
  }
}

@media screen and (min-width: 576px) {
  .headline {
    font-family: "Source Sans Pro";

    font-style: Medium;
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (min-width: 768px) {
  .headline {
    font-family: "Source Sans Pro";

    font-style: Medium;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .headline {
    font-family: "Source Sans Pro";

    font-style: Medium;
    font-size: 28px;
    line-height: 36px;
    color: #2c2c2c;
    vertical-align: Center;
  }
}
