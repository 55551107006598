.css-15jbhc-MuiModal-root-MuiDialog-root {
  position: relative !important;
}

.css-15jbhc-MuiModal-root-MuiDialog-root {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(3px) !important;
  background-color: rgba(255, 255, 255, 0.35) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .css-1b850ve-MuiModal-root-MuiDialog-root .MuiDialog-container{
  height: auto !important;
 
} */

/* .css-1b850ve-MuiModal-root-MuiDialog-root.MuiDialog-root{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */


.css-1b850ve-MuiModal-root-MuiDialog-root .MuiDialog-container{
 
  overflow: auto !important;
}

.css-1b850ve-MuiModal-root-MuiDialog-root{
  overflow: auto !important;
}