.pdfName {
  font-family: "Source Sans Pro";

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  color: #3d3d3d;
  max-width: 236px;
}


.pdfSize {
  font-family: "Source Sans Pro";

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #9d9d9d;
}

.pdfType {
  font-family: "Source Sans Pro";

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #3d3d3d;
}
.pdfUploadPercantage {
  font-family: "Source Sans Pro";

  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #9d9d9d;
}

.MuiDrawer-paperAnchorRight{
  margin-top: 0 !important;
}

.addProductPhotoDiv {
  height: 84px;
  width: 84px;
  border: 1px solid #bebebe;
  border-radius: 8px;
  background: #fbfbfb;
}

.addMyTaskPhotoDiv {
  height: 156px;
  width: 156px;
  border: 1px solid #bebebe;
  border-radius: 8px;
  background: #fbfbfb;
}

.imageHoverPro{
  position: absolute;
    width: 100%;
    height: 100%;
    background: #2C2C2C;
    opacity: 0.8;
    border-radius: 7px;
}
.addProductImageTag {
  border-radius: 2px;
  width: 69px;
  height: 61px;
  bottom: 10px;
  left: 6px;
}
.addMyTasksImageTag {
  border-radius: 2px;
  width: 240px;
  height: 172px;
  /* bottom: 156px; */
  /* left: 6px; */
}
.uploadTextMargin {
  margin-top: -18px;
  width: fit-content;
}

.addProductImageDelete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 100%;
  height: 100%;

  background-blend-mode: multiply;
  /* border: 1px solid #BEBEBE; */
  border-radius: 8px;
  /* background: #FBFBFB; */
  backdrop-filter: blur(4px);
}

.billsCrossIcon {
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 5px;
}

.addProductformBillDivider {
  border-top: 1px solid #f5f5f5;
  border-radius: 5px;
  margin: 20px 0px -18px 0px;
}

.addProductErrorText {
  font-family: "Source Sans Pro";

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #19191d;
}

.addProductErrorFileName {
  font-family: "Source Sans Pro";

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #3d3d3d;
}

.addProductErrorDialogTile {
  font-family: "Source Sans Pro";

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
}

.downloadPhotosName {
  font-family: "Source Sans Pro";

  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #2c2c2c;
}

.downloadPhotosText {
  font-family: "Source Sans Pro";

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #707070;
}

#addBillError{
  margin-top: -5px;
}

.AddProducturlLabel {
  font-family: "Source Sans Pro";

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #707070;
}

.AddProductErrorDownloadPhotoLabel {
  font-family: "Source Sans Pro";

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #5b5b5b;
}
@media (min-width: 570px) {
  .css-1ung250-MuiModal-root-MuiDialog-root{
    position: absolute !important;
    }
    /* body{
      overflow-y: auto !important;
    } */
}


@media (max-width: 570px) {
  .addProductPhotoDiv {
    width: 58px;
    height: 58px;
  }
  .addProductImageTag {
    width: 45px;
    height: 49px;
    bottom: 4px;
    left: 5px;
  }
}

@media (min-width: 570px) {
  
  
  
  .addproductMobilePosition {
    margin-top: 0;
    bottom: 10px;
  }
}
