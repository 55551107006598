.h-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}
.image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134.4px;
  height: 96px;
  margin-top: 12px;
  padding: 8px 16px;
  object-fit: cover;
}
.box-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.botto-box {
  width: 408px;
  height: 184px;
  background: #fbfbfb;
  border: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  overflow-y: hidden
}

